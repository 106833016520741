import { createLazyFileRoute } from "@tanstack/react-router";
import { ObjectStorageView } from "../../../../modules/storage/objectStorage/ObjectStorageView.tsx";

export const Route = createLazyFileRoute("/_main/storage/bucket/$storageName/")({
    component: Component,
});

function Component() {
    const { storageName } = Route.useParams();
    return <ObjectStorageView key={storageName} storageName={storageName} />;
}

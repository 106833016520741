import { BillingSelect, type BillingSelectLoaderProps } from "./BillingSelect.tsx";

export interface EditableBillingSelectProps
    extends Pick<
        BillingSelectLoaderProps,
        "valueKey" | "allowClosedAccounts" | "isLoading" | "onChange" | "isReadOnly" | "defaultOpen"
    > {}

export function EditableBillingSelect({ ...props }: EditableBillingSelectProps) {
    return <BillingSelect enableInlineEditing {...props} />;
}

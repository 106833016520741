import T from "../../../components/forms/TextField.module.css";

import { FieldError, Input, TextField } from "react-aria-components";
import { useForm, type SubmitHandler } from "react-hook-form";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../../components/forms/FieldButtonGroup.tsx";
import { WHookForm } from "../../../components/forms/WHookForm.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { addImpersonatorMutation, type AccessDelegationWithType } from "./apiOperations.ts";

export interface AddImpersonationFormProps {
    obj: AccessDelegationWithType;
    onClose: () => void;
}

export function AddImpersonationForm({ obj, onClose }: AddImpersonationFormProps) {
    const createImpersonationMutation = useStandardMutation(addImpersonatorMutation);

    interface Inputs {
        grantee_username: string;
    }

    /// Form
    const form = useForm<Inputs>({
        disabled: createImpersonationMutation.isPending,
    });
    const { register } = form;

    const onSubmit: SubmitHandler<Inputs> = async ({ grantee_username }) => {
        await createImpersonationMutation.mutateAsync({
            grantee_username,
            delegation_id: obj.id,
        });
        onClose();
    };

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <TextField isRequired type="email" aria-label="Grantee username" className={T.TextField}>
                <FieldButtonGroup>
                    <Input
                        className={T.Input}
                        data-testid="AddImpersonationInput"
                        placeholder="Users email"
                        autoFocus={true}
                        {...register("grantee_username")}
                    />
                    <WSubmitButton icon="jp-icon-add" isLoading={createImpersonationMutation.isPending}>
                        Add User Email
                    </WSubmitButton>
                    <WButton action={onClose}>Close</WButton>
                </FieldButtonGroup>
                <FieldError />
            </TextField>
        </WHookForm>
    );
}

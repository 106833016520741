import type { BillingAccountId } from "@warrenio/api-spec/spec.oats.gen";
import { eBillingAccountQueryAtom } from "../../modules/billing/billingAccountQuery.ts";
import {
    EditableBillingSelect,
    type EditableBillingSelectProps,
} from "../../modules/billing/forms/EditableBillingSelect.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceLink } from "../ResourceLink.tsx";
import { DetailsTableRow } from "./DetailsTableRow.tsx";

export interface DetailsTableBillingAccountProps extends Omit<EditableBillingSelectProps, "isReadOnly"> {
    isGlobal?: boolean;
}

export function DetailsTableBillingAccount({ isGlobal, ...props }: DetailsTableBillingAccountProps) {
    const isReadOnly = !props.onChange;
    return (
        <DetailsTableRow title="Billing account:">
            {isGlobal ? "All" : <EditableBillingSelect isReadOnly={isReadOnly} {...props} />}
        </DetailsTableRow>
    );
}

export function DetailsTableBillingAccountReadOnly({ billing_account_id }: { billing_account_id: BillingAccountId }) {
    const data = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    const account = data.get(billing_account_id);

    return (
        <DetailsTableRow title="Billing account:">
            {account ? (
                <ResourceLink item={account.account} title={account.title} />
            ) : (
                <span className="text-error">Unknown</span>
            )}
        </DetailsTableRow>
    );
}

import type { AccessDelegationWithType } from "../modules/access/delegation/apiOperations.ts";
import type { AccessImpersonationWithType } from "../modules/access/impersonation/apiOperations.ts";
import type { ApiTokenWithType } from "../modules/access/token/apiOperations.ts";
import { getResourceLink } from "../modules/api/resourceTypes.tsx";
import type { BillingAccountWithType } from "../modules/billing/billingAccountQuery.ts";
import type { VirtualMachineLoc } from "../modules/compute/vmQuery.ts";
import type { MetalListItemType } from "../modules/metal/metalQuery.ts";
import type { IpAddressWithType } from "../modules/network/ipAddress/apiOperations.ts";
import type { LoadBalancerWithType } from "../modules/network/loadbalancer/apiOperations.ts";
import type { VpcWithType } from "../modules/network/vpc/apiOperations.ts";
import type { ManagedServiceLoc } from "../modules/services/servicesQuery.ts";
import type { StorageWithType } from "../modules/storage/objectStorage/apiOperations.ts";
import { WTooltip } from "./WTooltip.tsx";
import { WButton } from "./button/WButton.tsx";

// TODO: bucket, service, invoice ...
export type AnyResource =
    | VirtualMachineLoc
    | ManagedServiceLoc
    | VpcWithType
    | LoadBalancerWithType
    | IpAddressWithType
    | AccessDelegationWithType
    | ApiTokenWithType
    | StorageWithType
    | AccessImpersonationWithType
    | BillingAccountWithType
    | MetalListItemType;

export function ResourceLink({ title, item }: { title?: string; item: AnyResource }) {
    const action = getResourceLink(item);

    return (
        <WTooltip text="View">
            <WButton action={action} size="xs" color="primary" variant="ghost">
                {title}
            </WButton>
        </WTooltip>
    );
}

import type { BillingAccount } from "@warrenio/api-spec/spec.oats.gen";
import React from "react";
import { first } from "remeda";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import ContentPane from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { NoticeBlock } from "../../../components/NoticeBlock.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { SecretKey } from "../../../components/SecretKey.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../../components/table/DetailsTableBillingAccount.tsx";
import { MonthlyCostRow } from "../../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useConfig } from "../../../config.ts";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation, useStandardSuspenseQuery } from "../../api/useStandardMutation.ts";
import { isMobileDevice } from "../../main/mobile/isMobile.ts";
import { ContactSupportButton } from "../../support/ContactSupportButton.tsx";
import { storageListQueryWithPricesAtom } from "../storageListQuery.ts";
import {
    changeObjectStorageBAMutation,
    deleteObjectStorageKeyMutation,
    deleteObjectStorageMutation,
    generateObjectStorageKeyMutation,
    getS3ApiUrlQuery,
    getUserObjectStorageKeysQuery,
} from "./apiOperations.ts";

const S3FileBrowser = React.lazy(() => import("../s3/S3FileBrowser.tsx"));

const suspendedBlock = (
    <NoticeBlock icon="jp-warning-icon" button={<ContactSupportButton />}>
        One of your billing accounts has a problem and your buckets have been suspended. See billing account details for
        more information or <b>contact support</b> to resolve the issue.
    </NoticeBlock>
);

export function ObjectStorageView({ storageName }: { storageName: string }) {
    //#region Hooks
    const deleteMutation = useStandardMutation(deleteObjectStorageMutation);
    const changeBillingAccountMutation = useStandardMutation(changeObjectStorageBAMutation);

    const data = useSuspenseQueryAtom(storageListQueryWithPricesAtom);

    const obj = useDeletableResource(() => getResourceById(data, storageName, "bucket"), deleteMutation);
    //#endregion

    if (obj === undefined) {
        return <NavigateAfterDelete />;
    }

    const { name, $type, num_objects, created_at, billing_account_id, is_suspended, size_bytes, price } = obj;
    const sizeGB = (size_bytes / 1024 / 1024 / 1024).toFixed(2);

    async function onDelete() {
        await deleteMutation.mutateAsync({ name });
    }

    async function changeBillingAccount(billing_account: BillingAccount) {
        await changeBillingAccountMutation.mutateAsync({ body: { name, billing_account_id: billing_account.id } });
    }

    return (
        <>
            <Toolbar>
                <DeleteModal title="Delete Storage" modalAction={async () => await onDelete()}>
                    Deleting a storage "{name}" will permanently remove it and will not be accessible after that.
                </DeleteModal>
            </Toolbar>

            <ContentPane>
                {is_suspended && <div className="pb-3">{suspendedBlock}</div>}

                <h1 className="font-size-heading">{name}</h1>

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableRow title="Name:">
                            <ClipBoardTooltip>{name}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="Type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Size:">{sizeGB} GB</DetailsTableRow>
                        <DetailsTableRow title="Nr of files:">{num_objects}</DetailsTableRow>
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="Server:">
                            <S3ServerUrl />
                        </DetailsTableRow>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <DetailsTableRow />
                        <DetailsTableBillingAccount
                            valueKey={billing_account_id}
                            onChange={async (item) => await changeBillingAccount(item.account)}
                        />
                        <MonthlyCostRow price={price} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Storage Access Keys</h2>
                <ContentLoadingSuspense>
                    <AccessKeysContent />
                </ContentLoadingSuspense>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Files</h2>
                <ContentLoadingSuspense>
                    <FilesContent bucketName={name} isSuspended={is_suspended} />
                </ContentLoadingSuspense>
            </ContentPane>
        </>
    );
}

function S3ServerUrl() {
    const s3Url = useStandardSuspenseQuery(getS3ApiUrlQuery);

    const s3Server = new URL(s3Url.data.url).host;

    return <ClipBoardTooltip>{s3Server}</ClipBoardTooltip>;
}

function AccessKeysContent() {
    const generateAccessKeyMutation = useStandardMutation(generateObjectStorageKeyMutation);
    const deleteAccessKeyMutation = useStandardMutation(deleteObjectStorageKeyMutation);

    const storageKeys = useStandardSuspenseQuery(getUserObjectStorageKeysQuery);

    async function generateAccessKey() {
        await generateAccessKeyMutation.mutateAsync();
    }

    async function deleteAccessKey(access_key: string) {
        await deleteAccessKeyMutation.mutateAsync({ body: { access_key } });
    }

    return (
        <WTable
            afterTable={
                <WButton
                    color="primary"
                    size="bar"
                    variant="border"
                    icon="jp-icon-add"
                    action={async () => await generateAccessKey()}
                >
                    Create New Access Key
                </WButton>
            }
        >
            <thead>
                <tr>
                    <th>Access Key ID</th>
                    <th>Secret Access Key</th>
                    <th>Type</th>
                    <th />
                </tr>
            </thead>
            <WTableBody>
                {storageKeys.data.map(({ accessKey, secretKey }) => (
                    <tr key={accessKey}>
                        <td>
                            <ClipBoardTooltip>{accessKey}</ClipBoardTooltip>
                        </td>
                        <td>
                            <SecretKey value={secretKey} />
                        </td>
                        <td>Global</td>
                        <td className="text-right">
                            <DeleteModal
                                title="Delete Key Pair"
                                inTable
                                modalAction={async () => await deleteAccessKey(accessKey)}
                            >
                                Deleting access key "{accessKey}" will permanently remove it and cannot be used for
                                accessing storage API after that.
                            </DeleteModal>
                        </td>
                    </tr>
                ))}
            </WTableBody>
        </WTable>
    );
}

function FilesContent({ bucketName, isSuspended }: { bucketName: string; isSuspended: boolean }) {
    const storageKeys = useStandardSuspenseQuery(getUserObjectStorageKeysQuery);
    const {
        data: { url: s3url },
    } = useStandardSuspenseQuery(getS3ApiUrlQuery);

    const { s3ForcePathStyle } = useConfig();

    const firstAccess = first(storageKeys.data);

    return isSuspended ? (
        <p className="color-muted">{suspendedBlock}</p>
    ) : !firstAccess ? (
        <p className="color-muted">
            You need to have at least one access key for browsing files on this bucket. Click the button above to create
            one.
        </p>
    ) : (
        <S3FileBrowser
            clientOptions={{
                accessKeyId: firstAccess.accessKey,
                secretAccessKey: firstAccess.secretKey,
                region: "us-east-1", // Dummy region
                endpoint: s3url,
                forcePathStyle: s3ForcePathStyle,
            }}
            bucketName={bucketName}
            mobileView={isMobileDevice}
        />
    );
}

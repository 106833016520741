import { Suspense, type ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../../../components/forms/FormField.tsx";
import type { ResourceType } from "../../api/resourceTypes.tsx";
import { LimitedBillingAccountNoticeLoader } from "../LimitedBillingAccountNotice.tsx";
import { BillingSelect, type BillingSelectLoaderProps } from "./BillingSelect.tsx";

export interface BillingAccountInput {
    billing_account_id?: number;
}

export interface BillingAccountFieldProps extends Omit<BillingSelectLoaderProps, "onChange"> {
    description?: ReactNode;
    resourceType: ResourceType | undefined;
}

export function BillingAccountField({
    description = "Choose a billing account for your new resource to consolidate your resources under a suitable payment method.",
    ...props
}: BillingAccountFieldProps) {
    const { control } = useFormContext<BillingAccountInput>();
    const { isRequired, resourceType } = props;

    return (
        <Controller
            control={control}
            name="billing_account_id"
            render={({ field }) => (
                <>
                    <FormField isRequired={isRequired} label="Billing account" description={description}>
                        <BillingSelect
                            isDisabled={field.disabled}
                            valueKey={field.value ?? null}
                            onChange={(account) => field.onChange(account.id)}
                            {...props}
                        />
                    </FormField>

                    {(resourceType === "virtual_machine" || resourceType === "managed_service") && field.value && (
                        <FormField wide>
                            <Suspense>
                                <LimitedBillingAccountNoticeLoader baId={field.value} />
                            </Suspense>
                        </FormField>
                    )}
                </>
            )}
        />
    );
}

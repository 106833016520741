import type { BillingAccount } from "@warrenio/api-spec/spec.oats.gen";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { useConfig } from "../../config.ts";
import { TODO } from "../../dev/Todo.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ContactSupportButton } from "../support/ContactSupportButton.tsx";
import { useThemeProps } from "../theme/useTheme.ts";
import { TopUpNowButton } from "./ActionButtons.tsx";
import { useBillingAccount } from "./billingAccountQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { queryAtom } from "./restrictionLevelClearTopupThresholdQuery.ts";

export function LimitedBillingAccountNoticeLoader({ baId }: { baId: BillingAccount["id"] }) {
    const account = useBillingAccount(baId);
    return <LimitedBillingAccountNoticeContent account={account} />;
}

export function LimitedBillingAccountNoticeContent({ account }: { account: EBillingAccount }) {
    //#region Hooks
    const { restriction_level_clear_topup_threshold } = useSuspenseQueryAtom(queryAtom);
    const { limitedBillingAccountHideButton, limitedBillingAccountCopy } = useThemeProps();
    const { limitedBillingAccountConfig, kubernetesEnabled, billingEmail } = useConfig();
    //#endregion

    const { vcpu, ram_mb } = limitedBillingAccountConfig;

    return (
        account.isLimited && (
            <NoticeBlock
                icon="jp-warning-icon"
                button={
                    !limitedBillingAccountHideButton &&
                    (account.canTopUp ? <TopUpNowButton account={account} /> : <ContactSupportButton />)
                }
            >
                <p>
                    <b>BILLING ACCOUNT IS LIMITED</b>
                    <br />
                    This billing account has limited capabilities when creating resources.{" "}
                    {!!limitedBillingAccountCopy && limitedBillingAccountCopy.replace("{billingEmail}", billingEmail)}
                </p>

                {!limitedBillingAccountCopy && (
                    <>
                        <ul className="m-0 pl-5">
                            {!!vcpu && <li>CPU: {vcpu}</li>}
                            {!!ram_mb && <li>RAM: {ram_mb} MB</li>}
                            {kubernetesEnabled && <li>Kubernetes service can not be created.</li>}
                        </ul>

                        {account.isPostPay ? (
                            <p>Please contact support to remove the limit.</p>
                        ) : restriction_level_clear_topup_threshold ? (
                            <p>
                                Limit will be removed once the total top up amount reaches{" "}
                                <CurrencyPromo value={restriction_level_clear_topup_threshold} />
                                <TODO />.
                            </p>
                        ) : null}
                    </>
                )}
            </NoticeBlock>
        )
    );
}

import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { VmParameter } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { queryThen } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";

export type Response = VmParameter[];

export const queryKey: QueryKey = ["vm_parameters"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/api/parameters/vm", { signal }));
        },
    });
}

export const parametersQueryAtom = atomFromStandardQueryOptions(getQuery);

/** Map from parameter name to the param */
export const parametersByNameAtom = atom((get) =>
    queryThen(get(parametersQueryAtom), (data) => mapFrom(data, (param) => param.parameter)),
);

import { createLazyFileRoute } from "@tanstack/react-router";
import { DelegationView } from "../../../../../modules/access/delegation/DelegationView.tsx";

export const Route = createLazyFileRoute("/_main/access/delegation/$id/")({
    component: Component,
});

function Component() {
    const id = Number(Route.useParams().id);
    // NB: `key` prop ensures that the component state is reset when the route changes (to prevent eg. `isOpen` etc. states from remaining the same)
    return <DelegationView key={id} accessId={id} />;
}
